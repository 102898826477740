body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-red {
  color: red
}

.react-datepicker-wrapper {
  display: block !important;
}

.table.table-head-fixed thead tr:nth-child(1) th {
  z-index: 0;
}

.react-datepicker__close-icon {
  top: 6px !important;
}

.react-datepicker__close-icon::after {
  font-size: 14px !important;
  background-color: var(--primary-color) !important;
}

.react-datepicker-wrapper {
  display: block !important;
}

.table.table-head-fixed thead tr:nth-child(1) th {
  z-index: 0;
}

.react-datepicker__close-icon {
  top: 6px !important;
}

.react-select-custom{
  flex-wrap: nowrap;
}

.tema-ant-fundo {
  background-color: var(--primary-color)  !important
}

.tema-ant-texto {
  color: var(--primary-color)  !important
}

.contextMenuItem {
  font-size: 16px;
  color: #373A3C;
  line-height: 1.5;
}

.contextMenuItemHover {
  font-size: 16px;
  color: #FFFFFF;
  background-color: var(--primary-color);
  line-height: 1.5;
}